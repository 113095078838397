import { Box, Button, Step, StepLabel, Stepper, styled } from "@mui/material";
import AuthenticateLayout from "../../components/AuthenticateLayout";
import RewardIcon from "../../assets/icons/Reward.svg";
import SVG from "react-inlinesvg";
import React from "react";
import Dot from "../../assets/icons/Ellipse.svg";
import RewardWhiteIcon from "../../assets/icons/Reward-white.svg";
import RewardButton from "../../components/RewardButton";
import Gift3 from "../../assets/icons/gift3.svg";
import Gift4 from "../../assets/icons/gift4.svg";
import ReportButton from "../../components/ReportButton";
import { useQuery } from "react-query";
import { getAdsDetail } from "../../services/ads/ads.service";
import { useLocation, useNavigate } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { getValueByLanguage, isExpiredToday } from "../../utils/utils";
import { useTranslation } from "react-i18next";
import { links } from "../../constants/links";

const EngageWrapper = styled(Box)({
  height: "-webkit-fill-available",
  "& .MuiStepConnector-line": {
    borderColor: "#EF1B1B",
    borderLeftStyle: "dashed"
  },
  "& .MuiStepConnector-root": {
    marginLeft: 8
  },
  "& .MuiStepLabel-label": {
    fontSize: 16,
    lineHeight: "21px"
  },
  "& .MuiStepLabel-iconContainer": {
    paddingRight: 16,
  },
  "& .MuiStepper-root": {
    paddingBottom: 15,
    borderBottom: "1px solid #D9D9D9"
  },
  "& .MuiStepLabel-root": {
    padding: 0,
    overflow: "hidden",
  }
});

const RewardTitle = styled(Button)({
  justifyContent: "flex-start",
  backgroundColor: "rgba(17, 85, 170, 0.1)",
  color: "#1155AA",
  fontSize: 14,
  padding: "16px 22px",
  marginBottom: 25,
  pointerEvents: "none",
  "& svg": {
    marginRight: 10
  }
});

const Footer = styled(Box)(`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 10px 15px 40px 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background: #fff;
`);

const GetGiftBtn = styled(Button)({
  width: "100%",
  fontWeight: 400,
  fontSize: 14,
  height: 48,
  position: "relative",
  "&:hover": {
    backgroundColor: "#1155AA"
  },
  "& .giftIcon1": {
    position: "absolute",
    bottom: 0,
    left: "14%"
  },
  "& .giftIcon2": {
    position: "absolute",
    top: 0,
    right: "20%"
  }
});


const Engage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const id = location.pathname.split("/")[2];
  const { data, isLoading } = useQuery(["detail", id], () => getAdsDetail({ id: id }), { cacheTime: 0 });
  const steps = data && data?.languages && getValueByLanguage(data, "engagement_note") ?
    JSON.parse(getValueByLanguage(data, "engagement_note")) : [];
  if (data && data.status === "INITIATED" && isExpiredToday(data.expired_at)) {
    navigate(links.EARN_PAGE);
  }

  const getYouGift = (url: string) => {
    // window.location.href = url;
    window.open(url, "_blank");
  };

  return (
    <EngageWrapper>

      { isLoading && <Loading /> }
      <AuthenticateLayout
        background={data?.advertiser_banner}
        button={RewardButton}
      >
        <Box px={2.5} mt={6} pb={21} display="flex" justifyContent="center" flexDirection="column">
          <RewardTitle fullWidth variant="contained">
            <SVG src={RewardIcon} />
            {getValueByLanguage(data, "reward_summary")}
          </RewardTitle>
          <Stepper activeStep={steps?.length} orientation="vertical">
            {steps?.map((step: string, index: number) => (
              <Step key={index}>
                <StepLabel StepIconComponent={() => <SVG src={Dot} />}>
                  {step}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box display="flex" justifyContent="space-between">
            <Button
              color="info"
              size="large"
            >
              {t("termsAndCondition")}
            </Button>
            <ReportButton />
          </Box>
          <Footer>
            <GetGiftBtn
              variant="contained"
              startIcon={<SVG src={RewardWhiteIcon} />}
              onClick={() => getYouGift(data?.url)}
            >
              <SVG className="giftIcon1" src={Gift3}/>
              <SVG className="giftIcon2" src={Gift4}/>
              {t("getYourGiftNow")}
            </GetGiftBtn>
          </Footer>
        </Box>
      </AuthenticateLayout>


    </EngageWrapper>
  );
};

export default Engage;
