import { useCallback, useEffect, useLayoutEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { localStorageHelper } from "../../helpers/localstorage";

export const RestrictedRoute = ({
  children,
  restricted = false,
  isPrivate,

  title,
}: IRestrictedRoute): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();
  const redirect = useCallback(() => {
    if (restricted) {
      // navigate(links.STOCK_CHART, { replace: true });
    }
    // eslint-disable-next-line
  }, [isPrivate, history, restricted]);

  useLayoutEffect(() => {
    redirect();
  }, [redirect]);

  useEffect(() => {
    if (typeof title === "string") {
      document.title = title;
    }
    // eslint-disable-next-line
  }, []);

  // return <Route path={path} element={<>{children}</>} />;
  return (localStorageHelper.isLogin() && isPrivate) || !isPrivate ? children : <Navigate to="/login" />;
};

interface IRestrictedRoute {
  children?: any;
  restricted?: boolean;
  isPrivate?: boolean;
  layout?: any;
  path?: any;
  title?: string;
}
