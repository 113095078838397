import { Box, Button, styled, Typography } from "@mui/material";
import Logo from "../../../assets/logos/logo.png";
import ProtectImg from "../../../assets/images/Protect.png";
import { useNavigate } from "react-router-dom";
import { links } from "../../../constants/links";
import { postRegistration } from "../../../services/auth/auth.service";
import { useMutation } from "react-query";
import { Loading } from "../../../components/Loading";
import SVG from "react-inlinesvg";
import TagIcon from "../../../assets/icons/TagIcon.svg";
import React, { useState } from "react";
import { NotificationWrapper } from "../../use-reward";
import { useTranslation } from "react-i18next";
import { SUB_CAMPAIGN_ID_KEY } from "../../../constants";
import { renderPhoneNumber } from "../../../utils/utils";

const ConfirmWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  padding: "0 20px",
  height: "100%",
  justifyContent: "space-between",
});


export const LogoBanner = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    justify-content: space-between;
    height: 50px;
    width: -webkit-fill-available;
    align-items: center;
    padding: 20px 0 10px 0px;
    & .logo {
      width: 170px;
      height: 28px;
    }
    & .cancelBtn {
      font-weight: 500
    }
  `,
);

const BodyBox = styled(Box)({
  paddingTop: 60,
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",

});

const TextBox = styled(Box)({
  marginTop: 30,
  backgroundColor: "#F5F8FF",
  borderRadius: 10,
  padding: "25px 20px",
  "& p": {
    color: "#000000",
    opacity: 0.7
  },
  "& .termBtn": {
    fontSize: 14,
    padding: 0,
    marginTop: 30
  }
});

export const SubmitBtn = styled(Button)({
  marginBottom: 41,
  marginTop: 20,
  backgroundColor: "rgba(91, 99, 169, 0.1)",
  color: "#1155AA",
  height: 48,
  "&:hover": {
    backgroundColor: "rgba(91, 99, 169, 0.1)",
  }
});

const ConfirmPhoneNumber = ({ setIsConfirm, phoneNumber, prefix, appName, data }: any) => {
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation(postRegistration);
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const handleSubmit = () => {
    mutate({
      prefix: prefix,
      phoneNumber: phoneNumber,
      platform: "WHATSAPP",
      subCampaignId: localStorage.getItem(SUB_CAMPAIGN_ID_KEY),
    }, {
      onSuccess: () => {
        navigate(links.RESEND, { state: { phoneNumber, prefix, appName, data } });
      },
      onError: () => {
        setError(t("thePhoneNumber") + renderPhoneNumber(prefix, phoneNumber) + t("isNotRegisterWhatsApp"));
      }
    });
  };

  return (
    <ConfirmWrapper>
      <Box>
        {isLoading && <Loading/>}

        <LogoBanner>
          <img className="logo" src={Logo} alt="logo"/>
          <Button onClick={() => setIsConfirm(false)} className="cancelBtn" color="error">{t("cancel")}</Button>
        </LogoBanner>
        <BodyBox>
          { error &&
            <Box mb={1}>
              <NotificationWrapper>
                <Box mr={2}>
                  <SVG src={TagIcon} />
                </Box>
                <Box>
                  <Typography
                    className="detail"
                  >
                    {error}
                  </Typography>
                </Box>
              </NotificationWrapper>
            </Box>
          }
          <Box>
            <img src={ProtectImg} alt="confirm-phone-number" />
          </Box>
          <TextBox>
            <Typography variant="body2">
              {t("weNeedToMakeSureThat")}
              <b> {renderPhoneNumber(prefix, phoneNumber)} </b> {t("isYourPhoneNumber")}
            </Typography>
            <Typography mt={4} variant="body2">
              {t("needToProcess")}
            </Typography>
            <Button className="termBtn">{t("termsAndCondition")}</Button>
          </TextBox>

        </BodyBox>
      </Box>
      {
        !error && <SubmitBtn onClick={handleSubmit} className="submitBtn" variant="contained">{t("gotIt")}</SubmitBtn>
      }
      {
        error && (
          <SubmitBtn
            onClick={() => setIsConfirm(false)}
            className="submitBtn"
            variant="contained"
          >
            {t("enterAnotherPhoneNumber")}
          </SubmitBtn>
        )
      }
    </ConfirmWrapper>
  );
};

export default ConfirmPhoneNumber;
