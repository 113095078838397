// import { validationPatterns } from "../validation/pattern";
import i18next from "i18next";
import moment from "moment/moment";

export interface Props {
  children: React.ReactNode;
}

export const emailValidation = (email: string) => {
  return ("Valid Email");
  /*
  const regEx = validationPatterns.email;
  if (regEx.test(email)) {
    return("Valid Email");
  } else if (!regEx.test(email)) {
    return("Invalid email");
  }
  */
};

export const getValueByLanguage = (data: any, key: string) => {
  return data?.languages && data.languages[i18next.language] ? data?.languages[i18next.language][key] : "";
};

export const renderPhoneNumber = (prefix: string, phoneNumber: string) => {
  return (prefix ? prefix : "") + " " + (phoneNumber ?? "");
};

export const isExpiredToday = (date: string) => {
  const expiredData = moment(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format("X");
  const currentDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format("X");
  return expiredData < currentDate;
};