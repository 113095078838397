import {
  Box,
  Typography,
  styled,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import AuthenticateLayout from "../../../components/AuthenticateLayout";
import PhoneInput from "react-phone-input-2";

import ReactCountryFlag from "react-country-flag";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SVG from "react-inlinesvg";
import SelectCountry from "./SelectCountry";
import ConfirmPhoneNumber from "./ConfirmPhoneNumber";
import ChangeLanguage from "../../../components/ChangeLanguage";
import { APP_NAME, SUB_CAMPAIGN_ID_KEY } from "../../../constants";
import ReportButton from "../../../components/ReportButton";
import WhatsappIcon from "../../../assets/icons/whatsapp.svg";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getSubCampaignRegister } from "../../../services/ads/ads.service";
import { Loading } from "../../../components/Loading";
import { getValueByLanguage } from "../../../utils/utils";
import RewardIcon from "../../../assets/icons/Reward.svg";

const Wrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "-webkit-fill-available"
});

const LoginWrapper = styled(Box)({
  padding: "28px 20px 45px",
});

const Label = styled(Typography)({
  color: "#1155AA",
  fontSize: "0.75rem",
  fontWeight: 600,
});

const VoucherBox = styled(Box)({
  height: 72,
  backgroundColor: "rgba(17, 85, 170, 0.1)",
  border: "1px dashed #1155AA",
  borderRadius: 10,
  gap: 10,
  display: "flex",
  alignItems: "center",
  paddingLeft: 10,
  "& svg": {
    marginLeft: 10
  },
  "& img": {
    width: 42,
    height: 42
  }
});

const PhoneInputWrapper = styled(Box)({
  marginTop: 18,
  border: "1px solid #E6E8EC",
  borderRadius: 8,
  padding: 10,
  paddingLeft: 16,
  display: "flex",
  alignItems: "center",
  "& .react-tel-input": {
    width: "auto"
  },
  "& .form-control": {
    border: "none",
    borderLeft: "1px solid #E6E8EC",
    borderRadius: "unset",
    paddingLeft: 10,
    marginLeft: 10,
    fontWeight: 600,
    color: "#777E90",
    fontFamily: "Inter",
    width: "inherit"
  },
  "& .flag-dropdown": {
    display: "none",
    backgroundColor: "unset",
    border: "none"
  },
  "& .emojiFlag": {
    borderRadius: 2
  },
});

const BottomBox = styled(Box)(
  `
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    & button {
      font-size: 16px;
      font-weight: 600;
    };
    & .MuiButton-textInfo {
      color: #3B71FE;
    };
    & .MuiButton-textError {
      color: #EF1B1B;
    };
  `
);

const SubmitButton = styled(Button)({
  marginTop: 18,
  borderRadius: 10,
  width: "100%",
  backgroundColor: "#F5F8FF",
  color: "#141416",
  fontSize: 14,
  padding: "17px 20px",
  "&:hover": {
    backgroundColor: "#F5F8FF",
  },
  "& svg": {
    marginRight: 10,
    width: 20,
    height: 20
  }
});

const Login = () => {
  const [showSelectCountry, setShowSelectCountry] = useState(false);

  const [country, setCountry] = useState<any>([
    "Hong Kong",
    ["asia"],
    "hk",
    "852",
    ".... ...."
  ]);
  const [displayValue, setDisplayValue] = useState<any>("");
  const [isConfirm, setIsConfirm] = useState(false);
  const [appName, setAppName] = useState("");
  const { t } = useTranslation();

  const subCampaignId = localStorage.getItem(SUB_CAMPAIGN_ID_KEY);
  const { data, isLoading } = useQuery(["detail"], () => subCampaignId ?
    getSubCampaignRegister({ id: localStorage.getItem(SUB_CAMPAIGN_ID_KEY) })
    :
    null
  );

  const handleSelectCountry = (value: any) => {
    setShowSelectCountry(false);
    setCountry(value);
    setDisplayValue(displayValue.replace(country[3], value[3]));

  };

  const handleOnChange = (value: any) => {
    setDisplayValue(value);
  };
  return (
    <>
      {
        isLoading && <Loading />
      }
      {
        isConfirm
          ?
          <ConfirmPhoneNumber
            setIsConfirm={setIsConfirm}
            phoneNumber={displayValue.replace(country[3], "")}
            prefix={"+" + country[3]}
            appName={appName}
            data={data}
          />
          : (
            showSelectCountry
              ? (
                <SelectCountry setShowSelectCountry={setShowSelectCountry} setCountry={handleSelectCountry} />
              )
              : (
                <Wrapper>
                  <AuthenticateLayout
                    button={() => <ChangeLanguage />}
                  >
                    <LoginWrapper>
                      <Box>
                        <VoucherBox>
                          {
                            subCampaignId ? (
                              <>
                                <img src={data?.advertiser_logo} alt="icon" />
                                <Label>
                                  {
                                    getValueByLanguage(data, "reward_summary")
                                  }
                                </Label>
                              </>
                            ) :
                              <>
                                <SVG src={RewardIcon} />
                                <Label>
                                  {t("engageAndEarn")}
                                </Label>
                              </>
                          }

                        </VoucherBox>
                        <PhoneInputWrapper>
                          <Box display="flex" alignItems="center" onClick={() => setShowSelectCountry(true)}>
                            {
                              country[2] && (
                                <ReactCountryFlag
                                  countryCode={country[2] ?? ""}
                                  svg
                                  className="emojiFlag"
                                  style={{
                                    width: "1.5rem",
                                    height: "1em",
                                  }}
                                />
                              )
                            }
                            <KeyboardArrowDownIcon fontSize="small" sx={{ color: "#777E90", margin: "0 5px" }} />
                          </Box>
                          <PhoneInput
                            placeholder={t("yourPhoneNumber").toString()}
                            value={displayValue ?? ""}
                            onChange={handleOnChange}
                            country={country[2].toLowerCase() ?? ""}
                            // autoFormat={false}
                            inputProps={{
                              name: "phone",
                              required: true,
                              autoFocus: true,
                            }}
                            countryCodeEditable={false}
                          />

                        </PhoneInputWrapper>
                        <SubmitButton
                          variant="contained"
                          onClick={() => {
                            setIsConfirm(true);
                            setAppName(APP_NAME.WHATSAPP);
                          }}
                          disabled={!displayValue || !country}
                        >
                          <SVG src={WhatsappIcon} />
                          {`${t("receiveMessageVia")} WhatsApp`}
                        </SubmitButton>
                      </Box>

                    </LoginWrapper>
                  </AuthenticateLayout>
                  <BottomBox>
                    <Button
                      color="info"
                      size="large"
                    >
                      {t("termsAndCondition")}
                    </Button>
                    <ReportButton />
                  </BottomBox>
                </Wrapper>
              )
          )

      }
    </>
  );
};



export default Login;
