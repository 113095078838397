import { Box, Button, Grid, styled, Typography, Backdrop } from "@mui/material";
import BackgroundImg from "../../assets/images/bg-starbucks.png";
import AuthenticateLayout from "../../components/AuthenticateLayout";
import RewardButton from "../../components/RewardButton";
import React, { useState } from "react";
import { ExpiredDate, ViewMoreBtn } from "../list-rewards";
import QRCode from "react-qr-code";
import GiftIcon from "../../assets/icons/gift2.svg";
import SVG from "react-inlinesvg";
import { links } from "../../constants/links";
import { useLocation, useNavigate } from "react-router-dom";
import TagIcon from "../../assets/icons/TagIcon.svg";
import ReportButton from "../../components/ReportButton";
import { useQuery } from "react-query";
import { getAdsDetail } from "../../services/ads/ads.service";
import { Loading } from "../../components/Loading";
import { commonDateFormat } from "../../utils/date-format";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { isExpiredToday } from "../../utils/utils";
import CircularProgress from '@mui/material/CircularProgress';
import { QrReader } from "react-qr-reader";
import { getFullAPIURL } from "configs/http-client";
import CustomizedDialogs from "components/Dialog/CustomizedDialogs"
import { convertObjectToView } from "utils/content";

const UseRewardWrapper = styled(Box)({
  width: "100%",
  position: "relative",
  "& .body": {
    position: "absolute",
    width: "100%",
    paddingBottom: 32,
    "& .footerBox": {
      margin: "0 calc((100% - 333px) / 2)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: 15,
      "& .notice": {
        color: "#DA314F"
      }
    },
  }
});

const QRCodeWrapper = styled(Box)({
  width: 333,
  height: 365,
  borderRadius: "10px",
  background: "#fff",
  margin: "0 calc((100% - 333px) / 2)",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  border: "1px solid #EBEBEB",
  boxShadow: "0px 19px 38px rgba(58, 76, 130, 0.1)",
  padding: 20,
});

const RedeemCode = styled(Typography)({
  fontSize: 18,
  lineHeight: "21px",
  letterSpacing: "2px",
  color: "#000000"
});

const EarnMoreBtn = styled(Button)({
  width: "100%",
  marginTop: 23,
  fontSize: 14,
  lineHeight: "17px",
  fontWeight: 300,
  height: 48,
  "& .MuiButton-startIcon": {
    marginRight: 0
  }
});

export const NotificationWrapper = styled(Box)(`
  margin: 0 calc((100% - 333px) / 2);
  background-color: #FDEFE8;
  border: 1px dashed rgba(239, 91, 27, 0.5);
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 15px;
  display: flex;
  align-items: center;
  & .detail {
    color: #333333;
    font-size: 12px;
  };
  & .viewOtherBtn {
    font-size: 12px;
    font-weight: 400;
    padding: 0;
    line-height: 18px;
  }
`);

export const status = {
  COMPLETED: "COMPLETED"
};

const UseReward = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [openCamera, setOpenCamera] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogInfo, setDialogInfo] = useState({ title: '', body: '', footer: '' });
  const id = location.pathname.split("/")[2];
  const { data, isLoading } = useQuery(["detail"], () => getAdsDetail({ id: id }), { cacheTime: 0 });
  const goToRewardList = (use) => {
    navigate(links.EARN_PAGE + (use ? "?tab=1" : ""));
  };
  const used = data?.status === status.COMPLETED;
  const expired = isExpiredToday(data?.expired_at);

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const sendCoupon = (session_id) => {
    fetch(
      getFullAPIURL("/api/post/redeem-coupon/v-front"),
      {
        method: "POST",
        headers: {
          "session-id": localStorage.getItem("session-id"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          session_id: session_id,
          coupon_code: data?.coupon_code,
        }), // body data type must match "Content-Type" header
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setOpenDialog(true)
        setDialogInfo({ title: <img src={data?.reward_banner ?? BackgroundImg} width={"100%"} />, body: convertObjectToView(data), footer: '' })
      })
      .catch((error) => {
        setOpenDialog(true)
        setDialogInfo({ title: <img src={data?.reward_banner ?? BackgroundImg} width={"100%"} />, body: convertObjectToView(error), footer: '' })
      });
  };

  return (
    <AuthenticateLayout
      background={data?.reward_banner ?? BackgroundImg}
      button={RewardButton}
    >
      {isLoading && <Loading />}
      <CustomizedDialogs open={openDialog} onClose={handleDialogClose} {...dialogInfo} />
      <UseRewardWrapper>
        <Box className="body" sx={{ top: (used || expired) ? -185 : -110 }}>
          {
            (used || expired) && (
              <NotificationWrapper>
                <Box mr={2}>
                  <SVG src={TagIcon} />
                </Box>
                <Box>
                  <Typography
                    className="detail"
                  >
                    {
                      used ? t("offerAlreadyInUse") : t("useVoucherExpired")
                    }
                  </Typography>
                  <Button onClick={() => goToRewardList(false)} className="viewOtherBtn">{t("viewOther")}</Button>
                </Box>
              </NotificationWrapper>
            )
          }
          <QRCodeWrapper>
            <Button
              color="info"
              size="large"
            >
              {t("termsAndCondition")}
            </Button>
            <Grid mt={0.5} mb={2} container justifyContent="center">
              <Typography
                mr={0.5}
                sx={{ fontSize: 14, color: "#8D8D8D", fontWeight: 400 }}
              >
                {t("expiredDate")}
              </Typography>
              <ExpiredDate
                sx={{ fontSize: 14, fontWeight: 400 }}
              >
                {commonDateFormat(moment(data?.expired_at))}
              </ExpiredDate>
            </Grid>
            <QRCode
              size={172}
              value={data?.coupon_code ?? ""}
              viewBox={"0 0 256 256"}
              fgColor={(used || expired) ? "#DADADA" : "#42436A"}
            />
            <Typography
              mt={1}
              sx={{ fontSize: 14, color: "#8D8D8D", fontWeight: 400 }}
            >
              {t("yourRedeemCode")}
            </Typography>
            <RedeemCode mt={1}>{data?.coupon_code}</RedeemCode>
          </QRCodeWrapper>
          <Box className="footerBox">
            {
              !used && !expired && (
                <Typography sx={{ color: "#000000" }}>
                  <span className="notice">*</span> {t("giveThisCodeToStore")}
                </Typography>
              )
            }
            <EarnMoreBtn
              startIcon={<SVG src={GiftIcon} />}
              variant="contained"
              onClick={() => { setOpenCamera(!openCamera); }}
            >
              {t("openCamera")}
            </EarnMoreBtn>
            <Grid container mt={2}>
              {
                !used && !expired && <Grid item xs={6}>
                  <ViewMoreBtn
                    onClick={() => goToRewardList(true)}
                    fullWidth
                    variant="contained"
                  >
                    {t("useLater")}
                  </ViewMoreBtn>
                </Grid>
              }

              <Grid item xs={(used || expired) ? 12 : 6}>
                <ReportButton
                  size="medium"
                  fullWidth
                  sx={{
                    fontSize: 14,
                    height: 48
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openCamera}
          onClick={() => {
            setOpenCamera(!openCamera);
          }}
        >
          <div style={{ position: "absolute", height: "100%", top: "48%" }}>
            <CircularProgress color="inherit" />
          </div>
          <Box m={3} pt={1} style={{ width: "100%" }}>
            {openCamera && (
              <QrReader
                constraints={{ facingMode: "environment" }}
                onResult={(result) => {
                  if (result) {
                    setOpenCamera(false);

                    const url = new URL(result?.text.toString());
                    const params = new URLSearchParams(url.search);
                    const session_id = params.get("session_id");
                    sendCoupon(session_id);
                  }
                }}
                style={{ width: "100%" }}
              />
            )}
          </Box>
        </Backdrop>
      </UseRewardWrapper>
    </AuthenticateLayout>
  );
};

export default UseReward;
