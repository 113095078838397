import SVG from "react-inlinesvg";
import enFlag from "../../assets/icons/en.svg";
import hkFlag from "../../assets/icons/hk.svg";
import { Box, Button, IconButton, List, ListItem, ListItemText, Modal, styled, Typography } from "@mui/material";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "../../assets/icons/close.svg";
import { SelectBox } from "../../pages/authenticate/login/SelectCountry";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { LIST_LANGUAGE_ENUM } from "../../constants";

const ENGLISH = LIST_LANGUAGE_ENUM.EN;
const CANTONESE = LIST_LANGUAGE_ENUM.ZH_HK;

const ChangeLanguageBtn = styled(Button)({
  fontSize: 10,
  fontWeight: 400,
  color: "#000000",
  padding: 0,
  "& .flag": {
    width: 18,
    height: 12,
    marginRight: 6,
  },
  "&.onlyShowFlag": {
    minWidth: "unset",
    "& .flag": {
      width: 22,
    },
  }
});

const style = {
  position: "absolute",
  bottom: 0,
  left: 0,
  width: "100%",
  bgcolor: "background.paper",
  boxShadow: "4px 0px 10px rgba(0, 0, 0, 0.0968914)",
  p: "25px",
  borderRadius: "20px 20px 0px 0px"
};

const CloseBtn = styled(IconButton)({
  padding: 0,
});

const SelectLanguage = styled(SelectBox)({
  padding: 0,
  "& .flag": {
    "& svg": {
      width: 24,
      height: 16,
    }
  }
});

const ChangeLanguage = ({ onlyShowFlag = false }: { onlyShowFlag?: boolean }) => {
  const [lang, setLang] = useState(i18next.language);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();

  const LIST_LANGUAGE = [
    {
      label: t("english"),
      value: ENGLISH,
      flag: enFlag,
    },
    {
      label: t("cantonese"),
      value: CANTONESE,
      flag: hkFlag,
    }
  ];

  const handleChangeLanguage = async  (key: string) => {
    setLang(key);
    handleClose();
    await i18next.changeLanguage(key);
  };

  return (
    <>
      <ChangeLanguageBtn
        onClick={handleOpen}
        endIcon={
          <Box display="flex" alignItems="center">
             <SVG className="flag" src={lang === ENGLISH ? enFlag : hkFlag}/>
            {!onlyShowFlag &&<KeyboardArrowDownIcon sx={{ color: "#777E90", width: 12 }} />}
          </Box>
        }
        className={onlyShowFlag ? "onlyShowFlag" : ""}
      >
        {`${!onlyShowFlag ? (lang === ENGLISH ? t("english") : t("cantonese")) : ""}`}
      </ChangeLanguageBtn>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box display="flex" justifyContent="flex-end">
            <CloseBtn onClick={handleClose}>
              <SVG src={CloseIcon} />
            </CloseBtn>
          </Box>
          <Typography mt={2} sx={{ fontSize: 24 }} fontWeight="600">{t("selectLanguage")}</Typography>
          <SelectLanguage>
            <List
              sx={{
                width: 200,
                height: 230,
                bgcolor: "background.paper",
                overflow: "auto",
              }}
              dense
              component="div"
              role="list"
            >
              {LIST_LANGUAGE.map((value: any) => {
                const labelId = `transfer-list-all-item-${value.label}-label`;

                return (
                  <ListItem
                    key={labelId}
                    role="listitem"
                    button
                    onClick={() => handleChangeLanguage(value.value)}
                  >
                    <ListItemText primary={value.label} />
                    <ListItemText
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        fontWeight: 600
                      }}
                      className="flag"
                      primary={<SVG src={value.flag} />}
                    />
                  </ListItem>
                );
              })}
            </List>
          </SelectLanguage>
        </Box>
      </Modal>
    </>
  );
};

export default ChangeLanguage;
