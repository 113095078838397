export const links = {
  LOGIN: "/login",
  CONFIRM_PHONE_NUMBER: "/confirm-phone-number",
  RESEND: "/resend-message",
  EARN_PAGE: "/earn-rewards",
  ENGAGE_PAGE: "/engage/:id",
  USE_REWARD: "/use-reward/:id",
  VERIFY: "/verify-registration/:base64",
  REDEEM_COUPON: "/redeem-coupon",
  DOWNLOAD_APP: "/download-app",
  DOWNLOAD_APP_REDIRECT: "/download-app-redirect",
  REGISTER_FRONT: "/register-front",
};
