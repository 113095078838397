import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  styled,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SortIcon from "../../assets/icons/Sort.svg";
import SVG from "react-inlinesvg";
import { useLocation, useNavigate } from "react-router-dom";
import { links } from "../../constants/links";
import { useInfiniteQuery } from "react-query";
import { getAds } from "../../services/ads/ads.service";
import { Loading } from "../../components/Loading";
import { commonDateFormat } from "../../utils/date-format";
import moment from "moment";
import { status } from "../use-reward";
import { getValueByLanguage, isExpiredToday } from "../../utils/utils";
import { useTranslation } from "react-i18next";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import { TextBox } from "../authenticate/resend-phone-number";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const ListRewardsWrapper = styled(Box)({
  "& .MuiTab-root": {
    fontSize: 24,
    fontWeight: 700,
    color: "#D4D4D4",
    "&.Mui-selected": {
      "& .dot": {
        backgroundColor: "#EF5B1B"
      }
    }
  },
  "& .MuiTabs-indicator": {
    display: "none"
  },
  "& .dot": {
    height: 20,
    width: 23,
    backgroundColor: "#D4D4D4",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#fff",
    fontSize: 12
  }
});

const VoucherWrapper = styled(Box)({
  display: "flex",
  height: 130,
  "&.testTicket": {
    boxShadow: " 0px 0px 4px rgba(0, 0, 0, 0.2)",
    borderRadius: "10px",
    position: "relative",
    "& img": {
      width: "100%",
      height: "100%",
    },
    "& .ticket-divider": {
      position: "absolute",
      left: 122,
      width: 16,
      height: "100%",
      "& .divider-border": {
        "&:after": {
          "content": "''",
          position: "absolute",
          height: "100%",
          borderRight: "1px dashed #D9D9D9",
          left: 8,
        },
      },
      "& .notch": {
        position: "absolute",
        width: 16,
        height: 16,
        overflow: "hidden",
        top: -2,
        "&:after": {
          "content": "''",
          position: "relative",
          display: "block",
          width: 16,
          height: 16,
          borderRadius: "50%",
          top: "-50%",
          boxShadow: "inset 0 2px 4px rgba(41, 54, 61, 0.25)",
          backgroundColor: "#fff"
        },
        "&.bottom": {
          bottom: -2,
          top: "unset",
          "&:after": {
            top: "unset",
            bottom: "-50%",
            boxShadow: "inset 0 -2px 4px rgba(41, 54, 61, 0.25)"
          }
        }
      }
    },
    "& .ticket": {
      position: "relative",
      overflow: "hidden",
      padding: "25px 12px 25px 20px",
      "&.firstPart": {
        width: 122,
        padding: "8px 0px 8px 8px",
      },
      "&.secondPart": {
        width: "calc(100% - 122px)"
      },
    }
  },
  "& .subCampaignTitle": {
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
  }
});

export const ViewMoreBtn = styled(Button)({
  backgroundColor: "rgba(17, 85, 170, 0.1)",
  color: "#1155AA",
  fontSize: 14,
  height: 48,
  "&:hover": {
    backgroundColor: "rgba(17, 85, 170, 0.1)",
  }
});

export const ExpiredDate = styled(Typography)({
  fontSize: 12,
  fontWeight: 500,
});

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};


const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const ListWrapper = styled(List)({
  fontSize: 12,
  "& .MuiListItemIcon-root": {
    minWidth: 35,
  },
  "& .MuiListItemText-inset": {
    paddingLeft: 35
  },
  "& .MuiListItemButton-root": {
    paddingTop: 0,
    paddingBottom: 0,
    "& .active": {
      "& span": {
        fontWeight: 600
      }
    }
  }
});

const sortFields = {
  EXPIRED_DATE: "expire_date",
  ACTION_AND_REWARD: "action_and_reward",
  REWARD_SUMMARY: "reward_summary"
};

const sortDirection = {
  ASC: "",
  DESC: "-"
};

const sortEarnList = [
  {
    label: "sortByDate",
    key: sortFields.EXPIRED_DATE
  },
  {
    label: "sortByAlphabet",
    key: sortFields.ACTION_AND_REWARD
  }
];

const sortUseList = [
  {
    label: "sortByDate",
    key: sortFields.EXPIRED_DATE
  },
  {
    label: "sortByAlphabet",
    key: sortFields.REWARD_SUMMARY
  }
];

const MAX_PER_PAGE = 10;
const TABS = {
  EARN: 0,
  USE: 1,
  FUNC: 2,
};

const ListRewards = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { t } = useTranslation();
  const tab = searchParams.get("tab") ?? TABS.EARN;
  const [value, setValue] = useState(+tab);
  const [offset, setOffset] = useState(0);
  const [offsetUse, setOffsetUse] = useState(0);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [sortValue, setSortValue] = useState(sortFields.EXPIRED_DATE);
  const [direction, setDirection] = useState(sortDirection.ASC);
  const [sortUseValue, setSortUseValue] = useState(sortFields.EXPIRED_DATE);
  const [directionUse, setDirectionUse] = useState(sortDirection.ASC);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { data: rawData, isLoading, fetchNextPage, isFetching } = useInfiniteQuery({
    queryKey: ["earn", direction, sortValue],
    queryFn: ({ pageParam = 0 }) => {
      return getAds({ type: "earn", offset: pageParam, orderBy: direction + sortValue });
    },
    staleTime: Infinity,
  });
  const {
    data: rawUseData,
    isLoading: isLoadingUse,
    fetchNextPage: fetchNextUsePage,
    isFetching: isFetchingUse
  } = useInfiniteQuery({
    queryKey: ["use", directionUse, sortUseValue],
    queryFn: ({ pageParam = 0 }) => getAds({ type: "use", offset: pageParam, orderBy: directionUse + sortUseValue }),
    staleTime: Infinity,
  });

  useEffect(() => {
    const newOffset = rawData && rawData?.pages ? (rawData?.pages.length - 1) * 10 : 0;
    setOffset(newOffset);
  }, [rawData]);

  useEffect(() => {
    const newOffset = rawUseData && rawUseData?.pages ? (rawUseData?.pages.length - 1) * 10 : 0;
    setOffsetUse(newOffset);
  }, [rawUseData]);

  const navigate = useNavigate();

  const used = (data: any) => data?.status === status.COMPLETED;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    searchParams.set("tab", newValue.toString());
    navigate({ pathname: links.EARN_PAGE, search: searchParams.toString() });
  };

  const handleEngage = (item: any) => {
    navigate(links.ENGAGE_PAGE.replace(":id", item.id));
  };

  const handleUse = (item: any, used?: boolean) => {
    navigate(links.USE_REWARD.replace(":id", item.id), { state: { used: used } });
  };

  const handleLoadMore = async () => {
    await fetchNextPage({ pageParam: offset + 10 });
  };

  const handleTestRegister = async () => {
    navigate({ pathname: links.REGISTER_FRONT, search: searchParams.toString() });
  };

  const handleTestRedeem = async () => {
    navigate({ pathname: links.REDEEM_COUPON, search: searchParams.toString() });
  };

  const handleTestDownload = async () => {
    navigate({ pathname: links.DOWNLOAD_APP, search: searchParams.toString() });
  };

  const handleLoadMoreUse = async () => {
    await fetchNextUsePage({ pageParam: offsetUse + 10 });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeSort = (key: string) => {
    if (value === TABS.EARN) {
      if (key === sortValue) {
        if (direction === sortDirection.DESC) {
          setDirection(sortDirection.ASC);
        } else {
          setDirection(sortDirection.DESC);
        }
      } else {
        setSortValue(key);
        setDirection(sortDirection.ASC);
      }
    } else {
      if (key === sortUseValue) {
        if (directionUse === sortDirection.DESC) {
          setDirectionUse(sortDirection.ASC);
        } else {
          setDirectionUse(sortDirection.DESC);
        }
      } else {
        setSortUseValue(key);
        setDirectionUse(sortDirection.ASC);
      }
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const totalItems = rawData?.pages[0].total;
  const totalUseItems = rawUseData?.pages[0].total;
  const hasMore = rawData?.pages && rawData?.pages.length < Math.ceil(rawData?.pages[0].total / MAX_PER_PAGE);
  const hasMoreUse = (
    rawUseData?.pages && rawUseData?.pages.length < Math.ceil(rawUseData?.pages[0].total / MAX_PER_PAGE));
  const sortList = value === TABS.EARN ? sortEarnList : sortUseList;
  return (
    <ListRewardsWrapper>
      {(isLoading || isLoadingUse) && <Loading />}
      <Box sx={{ width: "100%" }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" pr={2.5}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label={t("earn")}
              icon={<span className="dot">{totalItems}</span>}
              {...a11yProps(TABS.EARN)}
              iconPosition="end"
            />
            <Tab
              icon={<span className="dot">{totalUseItems}</span>}
              label={t("use")}
              {...a11yProps(TABS.USE)}
              iconPosition="end"
            />
            <Tab
              icon={<span className="dot">{2}</span>}
              label={t("func")}
              {...a11yProps(TABS.FUNC)}
              iconPosition="end"
            />
          </Tabs>
          <Box>
            <IconButton onClick={handleClick}><SVG src={SortIcon} /></IconButton>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <ListWrapper
                sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
                aria-label="contacts"
              >
                {
                  sortList.map((sort) => (
                    <ListItem key={sort.key} disablePadding>
                      <ListItemButton onClick={() => handleChangeSort(sort.key)}>
                        <ListItemText
                          className={(value === TABS.EARN ? sortValue : sortUseValue) === sort.key ? "active" : ""}
                          primary={t(sort.label)}
                        />
                        {(value === TABS.EARN ? sortValue : sortUseValue) === sort.key &&
                          <IconButton>
                            {
                              ((value === TABS.EARN ? direction : directionUse) === sortDirection.DESC) ?
                                <ArrowDownwardRoundedIcon color="error" /> :
                                <ArrowUpwardRoundedIcon color="success" />
                            }
                          </IconButton>
                        }
                      </ListItemButton>
                    </ListItem>
                  ))
                }
              </ListWrapper>
            </Popover>
          </Box>
        </Box>
        <TabPanel value={value} index={0}>
          {
            rawData?.pages.map((page) => (
              <React.Fragment key={page.nextId}>
                {
                  page?.data.map((item: any) => (
                    <VoucherWrapper
                      key={item.id}
                      mb={2.5}
                      className="testTicket"
                      onClick={() => !isExpiredToday(item.expired_at) ? handleEngage(item) : null}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        className="ticket firstPart"
                      >
                        <img src={item.advertiser_logo} alt="rewards" />
                      </Box>
                      <Box className="ticket-divider">
                        <div className="divider-border" />
                        <div className="notch"></div>
                        <div className="notch bottom"></div>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        className="ticket secondPart"
                      >
                        <Box>
                          <Typography
                            sx={{ fontSize: 12, fontWeight: 600 }}
                            mb={1}
                            className={"subCampaignTitle"}
                          >
                            {getValueByLanguage(item, "action_and_reward")}</Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                          <Box>
                            <Typography
                              sx={{ fontSize: 12, color: "#8D8D8D", fontWeight: 500 }}
                            >
                              {t("expiredDate")}
                            </Typography>

                            <ExpiredDate
                              color={isExpiredToday(item.expired_at) ? "error" : ""}
                            >
                              {commonDateFormat(moment(item.expired_at))}
                            </ExpiredDate>
                          </Box>
                          <Button
                            sx={{ fontWeight: 400, fontSize: 12 }}
                            variant="outlined"
                            onClick={() => handleEngage(item)}
                            disabled={isExpiredToday(item.expired_at)}
                          >
                            {t("engage")}
                          </Button>
                        </Box>
                      </Box>
                    </VoucherWrapper>
                  ))
                }
              </React.Fragment>
            ))

          }
          {
            hasMore && <ViewMoreBtn
              onClick={handleLoadMore}
              fullWidth
              variant="contained"
              disabled={isFetching}
            >
              {t("viewMore")} - 10 {t("items")}
            </ViewMoreBtn>
          }
        </TabPanel>
        <TabPanel value={value} index={1}>
          {
            totalUseItems === 0 && <TextBox style={{ backgroundColor: "rgba(17, 85, 170, 0.1)" }}>
              <Box mr={2}>
                <InfoRoundedIcon color="info" style={{ color: "rgb(111 147 239)" }} />
              </Box>
              <Typography variant="subtitle1">
                {/* eslint-disable-next-line max-len */}
                <p>{t("pleaseEarnMore")}</p>
              </Typography>
            </TextBox>
          }
          {
            rawUseData?.pages?.map((page, index) => (
              <React.Fragment key={"page_" + index}>
                {
                  page.data.map((item: any) => (
                    <VoucherWrapper
                      key={"use-item-" + index + "-" + item.id}
                      mb={2.5}
                      className=" testTicket"
                      onClick={() =>
                        (used(item) || isExpiredToday(item.expired_at)) ? handleUse(item, true) : handleUse(item)
                      }
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        className="ticket firstPart"
                      >
                        <img src={item.reward_logo_with_text} alt="rewards" />
                      </Box>
                      <Box className="ticket-divider">
                        <div className="divider-border" />
                        <div className="notch"></div>
                        <div className="notch bottom"></div>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        className="ticket secondPart"
                      >
                        <Box>
                          <Typography
                            sx={{ fontSize: 12, fontWeight: 600 }}
                          >
                            {getValueByLanguage(item, "reward_summary")}
                          </Typography>
                          <Typography
                            sx={{ fontSize: 12 }}
                            className={"subCampaignTitle"}
                          >
                            {getValueByLanguage(item, "reward_use_criteria")}
                          </Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between">
                          <Box>{
                            !used(item) && (
                              <>
                                <Typography
                                  sx={{ fontSize: 12, fontWeight: 600, color: "#8D8D8D" }}
                                >
                                  {t("expiredDate")}
                                </Typography>
                                <ExpiredDate
                                  color={isExpiredToday(item.expired_at) ? "error" : ""}
                                >
                                  {commonDateFormat(moment(item.expired_at))}
                                </ExpiredDate>
                              </>
                            )
                          }
                          </Box>
                          {
                            used(item) ? <ExpiredDate color="error">{t("used")} {item.expired_at}</ExpiredDate> : (
                              <Button
                                sx={{ fontWeight: 400, fontSize: 12 }}
                                variant="contained"
                                onClick={() => handleUse(item)}
                                disabled={isExpiredToday(item.expired_at)}
                              >
                                {t("useNow")}
                              </Button>
                            )
                          }
                        </Box>
                      </Box>
                    </VoucherWrapper>
                  ))
                }
              </React.Fragment>
            ))
          }
          {
            hasMoreUse && (
              <ViewMoreBtn
                onClick={handleLoadMoreUse}
                fullWidth
                variant="contained"
                disabled={isFetchingUse}
              >
                {t("viewMore")} - 10 {t("items")}
              </ViewMoreBtn>
            )
          }

        </TabPanel>
        <TabPanel value={value} index={2}>
          <VoucherWrapper
            mb={2.5}
            className="testTicket"
            onClick={() => handleTestRegister}
          >
            <Button
              onClick={handleTestRegister}
              fullWidth
              sx={{ fontWeight: 400, fontSize: 18 }}
              variant="outlined"
            >
              {t("scanQRToRegister")}
            </Button>
          </VoucherWrapper>
          <VoucherWrapper
            mb={2.5}
            className="testTicket"
            onClick={() => handleTestRedeem}
          >
            <Button
              onClick={handleTestRedeem}
              fullWidth
              sx={{ fontWeight: 400, fontSize: 18 }}
              variant="outlined"
            >
              {t("scanQRToRedeem")}
            </Button>
          </VoucherWrapper>
          <VoucherWrapper
            mb={2.5}
            className="testTicket"
            onClick={() => handleTestDownload}
          >
            <Button
              onClick={handleTestDownload}
              fullWidth
              sx={{ fontWeight: 400, fontSize: 18 }}
              variant="outlined"
            >
              {t("scanQRToDownload")}
            </Button>
          </VoucherWrapper>
        </TabPanel>
      </Box>
    </ListRewardsWrapper>
  );
};

export default ListRewards;
