import React from "react";
import ChangeLanguage from "../../components/ChangeLanguage";
import { Box, styled } from "@mui/material";

const ChangeLanguageWrapper = styled(Box)(`
  position: fixed;
  right: 0;
  bottom: 20%;
  box-shadow: 0 5px 25px #0000000a, 0 3.2px 12px #00000014;
  border-top-left-radius: 32px;
  border-bottom-left-radius: 32px;
  padding: 6px 6px;
  z-index: 999;
  background-color: #fff;
`);

export const MainLayout = ({ children }: { children?: React.ReactNode }) => {
  return (
    <>
      <ChangeLanguageWrapper>
        <ChangeLanguage onlyShowFlag={true} />
      </ChangeLanguageWrapper>
      {children}
    </>
  );
};
