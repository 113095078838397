/*
import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.tsx</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
*/
import React, { Suspense, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Loading } from "./components/Loading";
import { ErrorFallback } from "./components/Error-fallback";
import { AppRoutes } from "./routes";
import ScrollToTop from "react-scroll-to-top";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";

import "./App.css";
import "./react-phone-number-input/style.css";
import "./react-phone-input-2/lib/style.css";
import moment from "moment";

function App(): JSX.Element {
  useEffect(() => {
    const latestAccessTimeString = localStorage.getItem("accessTime");
    const latestAccessTime = moment(latestAccessTimeString);
    if (moment().diff(latestAccessTime, "minutes") >= 1) {
      localStorage.setItem("accessTime", moment().format());
    }

  }, []);

  return (
    <Suspense fallback={<Loading />}>
      {/*loading action when api error*/}
      {/*<ErrorBoundary FallbackComponent={ErrorFallback}>{loading ? <Loading /> : <AppRoutes />}</ErrorBoundary>*/}
      {/* @ts-ignore */}
      <ScrollToTop style={{ borderRadius: "50%" }} smooth component={<ArrowUpwardRoundedIcon/>} />
      <ErrorBoundary FallbackComponent={ErrorFallback}>{<AppRoutes />}</ErrorBoundary>
    </Suspense>
  );
}

export default App;
