import React, { useContext, useState } from "react";
// import { MayBe } from "../types";
import { Props } from "../utils/utils";

export const UserContext = React.createContext<any>(null);

export const SetUserContext = React.createContext<React.Dispatch<React.SetStateAction<any>>>(() => null);

export const useUser = (): any => useContext(UserContext);

export const useSetUser = (): React.Dispatch<React.SetStateAction<any>> => useContext(SetUserContext);

export const UserProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  return (
    <UserContext.Provider value={user}>
      <SetUserContext.Provider value={setUser}>{children}</SetUserContext.Provider>
    </UserContext.Provider>
  );
};
