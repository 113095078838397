import { List, Typography, ListItem, Grid } from '@mui/material';

export function toTitleCase(str) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1);
        }
    );
}

export const convertObjectToView = (d) => {
    if (d.length && d.length > 0) {
        return <List>
            {d.map((k) => {
                return convertObjectToView(k)
            })}
        </List>
    }
    return <List>
        {
            Object.keys(d).map((k) => {
                return (
                    <ListItem>
                        <Grid container direction="column" spacing={1}>
                            <Grid item>
                                <Typography variant="button" display="block" gutterBottom>
                                    {toTitleCase(k)}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption" display="block" gutterBottom>
                                    {d[k] && typeof d[k] === "object" ? convertObjectToView(d[k]) : d[k]}
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItem>
                )
            })
        }
    </List>
}

