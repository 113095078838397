import { Loading } from "../../../components/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import { links } from "../../../constants/links";
import { useEffect } from "react";
import { SUB_CAMPAIGN_ID_KEY } from "../../../constants";

const VerifyQrCode = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const subCampaignId = searchParams.get(SUB_CAMPAIGN_ID_KEY);
  useEffect(() => {
    if (subCampaignId) {
      localStorage.setItem(SUB_CAMPAIGN_ID_KEY, subCampaignId);
      navigate(links.ENGAGE_PAGE.replace(":id", subCampaignId));
    } else {
      localStorage.removeItem(SUB_CAMPAIGN_ID_KEY);
      navigate(links.EARN_PAGE);
    }
  }, [subCampaignId, navigate]);

  return (
    <div>
       <Loading />
    </div>
  );
};

export default VerifyQrCode;
