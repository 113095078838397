import React from "react";
import { MainLayout } from "../layouts/main";

import Login from "../pages/authenticate/login";
import { links } from "../constants/links";
import ResendPhoneNumber from "../pages/authenticate/resend-phone-number";
import ListRewards from "../pages/list-rewards";
import Engage from "../pages/engage";
import UseReward from "../pages/use-reward";
import VerifyToken from "../pages/authenticate/verify-token";
import VerifyQrCode from "../pages/authenticate/verify-qr-code";
import ScanRedeemQR from "../pages/scan-redeem-qr";
import ScanDownloadQR from "../pages/scan-download-qr";
import ScanDownloadQRRedirect from "../pages/scan-download-qr-redirect";
import ScanRegisterQR from "../pages/scan-register-qr";

export interface IRoute {
  restricted?: boolean;
  layout?: React.ComponentType;
  title?: string;
  children: any;
  path: string;
}

const privateRoutes: IRoute[] = [
  {
    children: <Engage />,
    path: links.ENGAGE_PAGE,
    restricted: true,
    layout: MainLayout,
  },
  {
    children: <ListRewards />,
    path: links.EARN_PAGE,
    restricted: true,
    layout: MainLayout,
  },
  {
    children: <UseReward />,
    path: links.USE_REWARD,
    restricted: true,
    layout: MainLayout,
  },
];
const publicRoutes: IRoute[] = [
  {
    children: <VerifyQrCode />,
    path: "/",
    restricted: true,
  },
  {
    children: <Login />,
    path: links.LOGIN,
    restricted: true,
  },
  {
    children: <ResendPhoneNumber />,
    path: links.RESEND,
    restricted: true,
  },
  {
    children: <VerifyToken />,
    path: links.VERIFY,
    restricted: true,
  },
  {
    children: <ScanRedeemQR />,
    path: links.REDEEM_COUPON,
    restricted: true,
  },
  {
    children: <ScanDownloadQR />,
    path: links.DOWNLOAD_APP,
    restricted: true,
  },
  {
    children: <ScanDownloadQRRedirect />,
    path: links.DOWNLOAD_APP_REDIRECT,
    restricted: true,
  },
  {
    children: <ScanRegisterQR />,
    path: links.REGISTER_FRONT,
    restricted: true,
  },
];
const noLayoutRoutes: IRoute[] = [];
interface IConfigRoutes {
  appRoutes: IRoute[] | {};
  isPrivate?: boolean;
  layout?: React.FC;
}

export const configRoutes: IConfigRoutes[] = [
  {
    appRoutes: privateRoutes,
    isPrivate: true,
    layout: MainLayout,
  },
  {
    appRoutes: noLayoutRoutes,
  },
  {
    appRoutes: publicRoutes,
    layout: MainLayout,
  },
];
