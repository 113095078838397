import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { StylesProvider } from "@mui/styles";
import CssBaseline from "@mui/material/CssBaseline";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#1155AA"
    },
    error: {
      main: "#EF1B1B"
    },
    info: {
      main: "#3B71FE"
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        h1 {
          color: grey;
        };
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 16,
          textTransform: "inherit",
          fontWeight: 600,
          boxShadow: "unset",
          fontFamily: "Inter",
          borderRadius: "10px",
          "&:hover": {
            boxShadow: "none",
          }
        },
        text: {
          "&:hover": {
            backgroundColor: "transparent"
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Inter"
        },
        body2: {
          fontSize: 16
        },
        subtitle1: {
          fontSize: 14
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#000000",
          },
          textTransform: "capitalize",
          padding: "12px 20px"
        }
      }
    }
  },
});

export const ThemeContext = React.createContext();

const ThemeProviderWrapper = function (props) {

  return (
    <StylesProvider injectFirst>
      {/* <CacheProvider value={cacheRtl}> */}
      <ThemeContext.Provider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {props.children}
        </ThemeProvider>
      </ThemeContext.Provider>
      {/* </CacheProvider> */}
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
