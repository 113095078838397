import { Box, Button, styled } from "@mui/material";
import SVG from "react-inlinesvg";
import RewardWhiteIcon from "../../assets/icons/Reward-white.svg";
import React from "react";
import { links } from "../../constants/links";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const RewardButtonWrapper = styled(Button)({
  background: "rgba(255, 255, 255, 0.7)",
  color: "#EF637C",
  fontSize: 12,
  fontWeight: 700,
  padding: 10,
  "& .icon-wrapper": {
    width: 20,
    height: 20,
    borderRadius: "50%",
    backgroundColor: "#EF637C",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      width: 10,
      height: 9
    }
  },
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.7)",
  },
});

const RewardButton = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goToRewardList = () => {
    navigate(links.EARN_PAGE);
  };

  return (
    <RewardButtonWrapper
      variant="contained"
      color="error"
      onClick={goToRewardList}
      startIcon={<Box className="icon-wrapper"><SVG src={RewardWhiteIcon} /></Box>}
    >
      {t("reward")}
    </RewardButtonWrapper>
  );
};

export default RewardButton;
