import { Box, styled } from "@mui/material";
import BannerImg from "../../assets/images/banner1.png";
import Logo from "../../assets/logos/logo.png";

const MainContent = styled(Box)(
  () => `
  height: -webkit-fill-available;
  `,
);

const Header = styled(Box)(
  () => `
    width: 100%;
  `,
);

const HeaderLogoContainer = styled(Box)(
  () => `
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    
  `,
);

const Banner = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    align-items: flex-start;
    & .img {
      width: 100%;
      min-height: 211px;
      max-height: 277px;
      clip-path: ellipse(160% 100% at 50% 0%);
    }
    position: relative;
    top: 0;
    left: 0;  
  `,
);

const LogoBanner = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;  
    z-index: 10;
    height: 58px;
    width: 100%;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(20px);
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    & .logo {
      width: 170px;
      height: 28px;
    }
  `,
);



const AuthenticateLayout = ({ background, children, button }: { children: any, background?: any, button?: any }) => {
  return (
    <>
      <MainContent position={"relative"}>
        <Header>
          <HeaderLogoContainer>

            <LogoBanner>
              <img className="logo" src={Logo} alt="logo" />

              {button && button()}
            </LogoBanner>
            <Banner>
              <img className="img" alt="banner" src={background || BannerImg} />
            </Banner>
          </HeaderLogoContainer>
        </Header>
        {children}
      </MainContent>
    </>
  );
};

export default AuthenticateLayout;
