import React, { useState } from "react";
import { QrReader } from "react-qr-reader";
import {
  Box,
  Button,
  styled,
  TextField,
  Backdrop,
  Typography,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import AuthenticateLayout from "../../components/AuthenticateLayout";
import RewardButton from "../../components/RewardButton";
import SVG from "react-inlinesvg";
import RewardWhiteIcon from "../../assets/icons/Reward-white.svg";
import CircularProgress from "@mui/material/CircularProgress";
import Gift3 from "../../assets/icons/gift3.svg";
import app_store from "../../assets/images/app_store.png";
import play_store from "../../assets/images/play_store.png";
import Gift4 from "../../assets/icons/gift4.svg";
import { TextBox } from "../authenticate/resend-phone-number";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { getFullAPIURL } from "configs/http-client";
import CustomizedDialogs from "components/Dialog/CustomizedDialogs";
import { convertObjectToView } from "utils/content";
import BackgroundImg from "../../assets/images/bg-starbucks.png";
import banner from "assets/images/banner2.png"

const EngageWrapper = styled(Box)({
  height: "-webkit-fill-available",
  "& .MuiStepConnector-line": {
    borderColor: "#EF1B1B",
    borderLeftStyle: "dashed",
  },
  "& .MuiStepConnector-root": {
    marginLeft: 8,
  },
  "& .MuiStepLabel-label": {
    fontSize: 16,
    lineHeight: "21px",
  },
  "& .MuiStepLabel-iconContainer": {
    paddingRight: 16,
  },
  "& .MuiStepper-root": {
    paddingBottom: 15,
    borderBottom: "1px solid #D9D9D9",
  },
  "& .MuiStepLabel-root": {
    padding: 0,
    overflow: "hidden",
  },
});

const GetTextBox = styled(TextBox)({
  marginBottom: 30,
});

const GetInstruction = styled(Box)({
  marginBottom: 30,
  alignItems: "center",
});

const Footer = styled(Box)(`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 10px 15px 40px 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background: #fff;
`);

const GetGiftBtn = styled(Button)({
  width: "100%",
  fontWeight: 400,
  fontSize: 14,
  height: 48,
  position: "relative",
  "&:hover": {
    backgroundColor: "#1155AA",
  },
  "& .giftIcon1": {
    position: "absolute",
    bottom: 0,
    left: "14%",
  },
  "& .giftIcon2": {
    position: "absolute",
    top: 0,
    right: "20%",
  },
});

const GetGiftBtnDisabled = styled(GetGiftBtn) ({
  backgroundColor: "#888888",
  "&:hover": {
    backgroundColor: "#888888",
  },
})

const ScanDownloadQRRedirect = (props) => {
  let os = 'Not known';
  if (window.navigator.appVersion.indexOf('Win') !== -1) { os = 'windows'; }
  if (window.navigator.appVersion.indexOf('Mac') !== -1) { os = 'mac'; }
  if (window.navigator.appVersion.indexOf('Linux') !== -1) { os = 'linux'; }
  
  const [shouldShowThank, setsSouldShowThank] = useState(false);
  const [openCamera, setOpenCamera] = useState(false);
  const [couponCode, setCouponCode] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogInfo, setDialogInfo] = useState({
    title: "",
    body: "",
    footer: "",
  });
  const { t } = useTranslation();
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <EngageWrapper>
      <CustomizedDialogs open={openDialog} onClose={handleDialogClose} {...dialogInfo} />
      <AuthenticateLayout button={RewardButton} background={banner}>
        <Box m={2} pt={3}>
        <GetTextBox style={{ backgroundColor: "rgba(17, 85, 170, 0.1)" }}>
            <Box mr={2}>
              <InfoRoundedIcon
                color="info"
                style={{ color: "rgb(111 147 239)" }}
              />
            </Box>
            <Typography variant="subtitle1">
              {/* eslint-disable-next-line max-len */}
              <p>{t("downloadAppInstructionStart")}</p>
            </Typography>
          </GetTextBox>

          <GetGiftBtnDisabled
              variant="contained"
              startIcon={<SVG src={RewardWhiteIcon} />}
            >
              <SVG className="giftIcon1" src={Gift3} />
              <SVG className="giftIcon2" src={Gift4} />
              {t("openCamera")}
          </GetGiftBtnDisabled>

          <Grid align="center" mt={2} >

            <Typography
                mt={4} mb={2}
                sx={{ fontSize: 14, color: "#8D8D8D", fontWeight: 400 }}>
                {t("downloadAppInstruction1")}
            </Typography>

            {os == 'mac' && <img src={app_store} width="150px" />}
            {os == 'linux' && <img src={play_store} width="150px" />}
            {os != 'linux' && os != 'mac' && 
            <box>
              <img src={app_store} width="150px" />
              <img src={play_store} width="150px" />
            </box>
            }
            

            <Typography
                mt={1} mb={2}
                sx={{ fontSize: 14, color: "#8D8D8D", fontWeight: 400 }}
              >
                {t("downloadAppInstruction2")}
             </Typography>
             <GetGiftBtn
                mb="100px"
                variant="contained"
                startIcon={<SVG src={RewardWhiteIcon} />}
                onClick={() => {
                  // setOpenCamera(!openCamera);
                  const url = new URL(window.location.href);
                  const params = new URLSearchParams(url.search);
                  const session_id = params.get("session_id");

                  window.location.replace("https://lipios.smartretail.co/dagd/test?session_id="+session_id);
                  setTimeout(
                    () => {
                      setsSouldShowThank(true)
                    }, 
                    500
                  );                      

                }}
              >
                <SVG className="giftIcon1" src={Gift3} />
                <SVG className="giftIcon2" src={Gift4} />
                {t("downloadAppInstructionActivate")}
            </GetGiftBtn>

            { shouldShowThank &&
              <Grid align="center" mt={2} >
                <Typography
                mt={1} mb={2}
                sx={{ fontSize: 14, color: "#8D8D8D", fontWeight: 400 }}
                >
                {t("downloadAppThankYou")}
                </Typography>
              </Grid>
            }

          </Grid>

        </Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openCamera}
          onClick={() => {
            setOpenCamera(!openCamera);
          }}
        >
          <div style={{ position: "absolute", height: "100%", top: "48%" }}>
            <CircularProgress color="inherit" />
          </div>
          <Box m={3} pt={1} style={{ width: "100%" }}>
            {openCamera && (
              <QrReader
                constraints={{ facingMode: "environment" }}
                onResult={(result) => {
                  if (result) {
                    setOpenCamera(false);
                    const url = new URL(result?.text.toString());
                    const params = new URLSearchParams(url.search);
                    const session_id = params.get("session_id");
                  }
                }}
                style={{ width: "100%" }}
              />
            )}
          </Box>
        </Backdrop>
      </AuthenticateLayout>
    </EngageWrapper>
  );
};

export default ScanDownloadQRRedirect;
