import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page404 } from "../pages/page-404";
import { configRoutes } from "./config";
import { RestrictedRoute } from "./restricted-route";
import ScrollToTop from "../components/ScrollToTop";
import { MainLayout } from "../layouts/main";

export const AppRoutes: React.FC = () => {
  const mappedRoutes = configRoutes.map(({ appRoutes, isPrivate }) => {
    return (
      Array.isArray(appRoutes) &&
      appRoutes.map(({ children, path, layout }) => {
        return (
            <Route
              key={path}
              path={path}
              element={
                <RestrictedRoute isPrivate={isPrivate} layout={layout}>
                  {layout ? <MainLayout>{children}</MainLayout> : children}
                </RestrictedRoute>
              }
            >
            </Route>
        );
      })
    );
  });

  return (
    <BrowserRouter>
      <ScrollToTop>
      <Routes>
        {mappedRoutes}
        <Route path="*" element={<Page404 />}></Route>;
      </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};
