import axios from "axios";
import { httpStatus } from "./http-status";

export const baseURL = process.env.REACT_APP_API_URL || "https://sit-global.sp.smartretail.co/"
const engageURL = new URL('/api/smart-engage', baseURL)?.href

export const getFullAPIURL = (endpoint: string) => new URL(endpoint, baseURL)?.href

const config = {
  baseURL: engageURL,
  validateStatus: (status: number) => {
    return (
      (status >= httpStatus.StatusOK && status < httpStatus.StatusMultipleChoices) ||
      status === httpStatus.StatusBadRequest
    );
  },
  timeout: 60 * 1000, // Timeout
};
const httpClient = axios.create(config);
httpClient.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("session-id")) {
      config.headers["session-id"] = localStorage.getItem("session-id");
    }
    // config.headers["ngrok-skip-browser-warning"] = "69420";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error && error.response && error.response.status && error.response.status === 401) {
      localStorage.removeItem("session-id");
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default httpClient;
