import { Loading } from "../../../components/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getVerifyRegistration } from "../../../services/auth/auth.service";
import { links } from "../../../constants/links";

const VerifyToken = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const token = location.pathname.split("/")[2];

  const { data, isLoading } = useQuery(["detail"], () => getVerifyRegistration({ data: token }));
  if (data && data["session-id"]) {
    localStorage.setItem("session-id", data["session-id"]);
    if (data.subcampaign_id) {
      navigate(links.ENGAGE_PAGE.replace(":id", data.subcampaign_id));
    } else {
      navigate(links.EARN_PAGE);
    }
  }

  if (data && data.error_msg) {
    navigate(links.LOGIN);
  }

  return (
    <div>
      {
        isLoading && <Loading />
      }
    </div>
  );
};

export default VerifyToken;
