import { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  OutlinedInput, styled,
  Typography
} from "@mui/material";
import SVG from "react-inlinesvg";
import LookIcon from "../../../assets/icons/Look.svg";
import ClearIcon from "../../../assets/icons/Clear.svg";
import { rawCountries } from "../../../constants/phoneCode";
import { useTranslation } from "react-i18next";

export const SelectBox = styled(Box)(
  `
    padding: 0 25px;
    & .MuiList-root {
      width: inherit;
      height: 100%;
    };
    & .MuiListItem-root.MuiButtonBase-root {
      border-bottom: 1px solid #EAECF0;
      border-radius: unset;
      padding: 20px 0;
      color: #141416;
      font-size: 16px;
      & span {
        font-size: 16px;
      }
      &:first-child {
        // border-bottom: 1px solid #6e7278;
      }
    };
    & .dial-code {
      & span {
        font-weight: 600
      }
    };
    & .MuiInputBase-root {
      &.Mui-focused:hover {
        & .MuiOutlinedInput-notchedOutline {
          border-color: #3B71FE;
          border-width: 1px
        }
      };
     
    }
  `
);

const HeaderBox = styled(Box)({
  display: "flex",
  marginTop: 30,
  alignItems: "center",
  justifyContent: "space-between",
  "& .cancelBtn": {
    color: "#3B71FE",
    fontSize: 16,
    fontWeight: 600
  },
});



const SelectCountry = ({ setShowSelectCountry, setCountry }: any) => {
  const [search, setSearch] = useState("");
  const { t } = useTranslation();

  const handleChange = (e: any) => {
    setSearch(e.target.value);
  };

  return (
    <SelectBox>
      <HeaderBox>
        <Typography variant="h4" fontWeight="600">{t("selectCountry")}</Typography>
        <Button className="cancelBtn" onClick={() => setShowSelectCountry(false)}>{t("cancel")}</Button>
      </HeaderBox>
      <FormControl fullWidth sx={{ m: 1, margin: "10px 0" }}>
        <OutlinedInput
          id="outlined-adornment-amount"
          startAdornment={<InputAdornment position="start"><SVG src={LookIcon} /></InputAdornment>}
          endAdornment={
            <InputAdornment
              onClick={() => setSearch("")}
              position="start"
            >
              <SVG src={ClearIcon}
              />
            </InputAdornment>
          }
          label=""
          onChange={handleChange}
          value={search}
        />
      </FormControl>
      <List
        sx={{
          width: 200,
          height: 230,
          bgcolor: "background.paper",
          overflow: "auto",
        }}
        dense
        component="div"
        role="list"
      >
        {rawCountries
          .filter((e: any) => (e[0] as string).toLowerCase().indexOf(search.toLowerCase()) === 0)
          .map((value: any) => {
          const labelId = `transfer-list-all-item-${value[0]}-${value[3]}-label`;

          return (
            <ListItem
              key={labelId}
              role="listitem"
              button
              onClick={() => setCountry(value)}
            >
              <ListItemText primary={value[0]} />
              <ListItemText
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontWeight: 600
                }}
                className="dial-code"
                primary={`+${value[3]}`}
              />
            </ListItem>
          );
        })}
      </List>
    </SelectBox>
  );
};

export default SelectCountry;
