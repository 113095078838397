import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { I18N_FALLBACK_LNG, I18N_WHITELIST } from "./const";
import { LIST_LANGUAGE_ENUM } from "./constants";
// import translationEN from "./locales/en/translation.json";
// import translationZH_HK from "./locales/zh-hk/translation.json";
const translationEN = {
  "selectLanguage": "Select Language",
  "termsAndCondition": "Terms and Condition",
  "reportError": "Report Error",
  "receiveMessageVia": "Receive Message via",
  "continueIn": "Continue in",
  "yourPhoneNumber": "Your phone number",
  "weNeedToMakeSureThat": "We need to make sure that",
  "isYourPhoneNumber": "is your phone number.",
  "cancel": "Cancel",
  "needToProcess": "We will process your data in compliance with personal data laws. It will never be shared without your consent.",
  "gotIt": "Got it",
  "enterAnotherPhoneNumber": "Enter another phone number",
  "thePhoneNumber": "The phone number ",
  "isNotRegisterWhatsApp": " is not registered with WhatsApp",
  "reachMaximumResendTime": "You have reached the maximum number of times to resend messages. Please enter your phone number again",
  "weJustSendTo1": "We just sent a message to your",
  "weJustSendTo2": "",
  "resend": "Resend",
  "reenterPhoneNumber": "Re-enter phone number",
  "dontReceive": "Don\\'t receive any message?",
  "reward": "Rewards",
  "getYourGiftNow": "GET YOUR GIFT NOW",
  "earn": "Earn",
  "use": "Use",
  "func": "Func",
  "engage": "Engage",
  "expiredDate": "Expiry date:",
  "viewMore": "View more",
  "items": "Items",
  "useNow": "Use now",
  "used": "Used",
  "earnMore": "Earn more",
  "useLater": "Use later",
  "yourRedeemCode": "Your redeem code",
  "giveThisCodeToStore": "Please give this code to store employee",
  "viewOther": "View other",
  "selectCountry": "Select country",
  "offerAlreadyInUse": "This offer is already used, but there are still other coupons waiting for you.",
  "english": "English",
  "cantonese": "\u5ee3\u6771\u8a71",
  "useVoucherExpired": "This campaign has run out of coupon , but there are still other coupons waiting for you.",
  "engageAndEarn": "Engage and earn free reward",
  "pleaseEarnMore": "Please visit Earn page to engage and earn rewards.",
  "sortByDate": "Sort by Date",
  "sortByAlphabet": "Sort by Alphabet",
  "openCamera": "Open Camera to Scan QR Code",
  "enterOUCode": "Please enter OU Code",
  "enterCouponCode": "Enter your coupon code",
  "scanQRToRegister": "Scan QR to Register",
  "scanQRToRedeem": "Scan QR to Redeem",
  "scanQRToDownload": "Scan QR to Download App",
  "howToRegister": "Please enter the OU code created on the Smart Manager platform, and then scan the QR code on the vending machine.",
  "howToRedeem": "Please enter the coupon code created on the Smart Manager platform, and then scan the QR code on the vending machine.",
  "pasteURL": "Paste your WhatsApp verification link",
  "pasteURLError": "Please copy your WhatsApp verification link from whatsapp message.",


  "downloadAppInstructionStart": "Please scan the QR code on the vending machine to start the coupon redemption",
  "downloadAppThankYou": "Your coupon has been added!",
  "downloadAppInstruction1": "1. Please download the app from App Store and come back this page",
  "downloadAppInstruction2": "2. Please open the app via below button",
  "downloadAppInstructionActivate": "Open App and Get Coupon",

}

// https://tool.chinaz.com/tools/unicode.aspx
const translationZH_HK = {
  "selectLanguage": "\u9078\u64c7\u8a9e\u8a00",
  "termsAndCondition": "\u689d\u6b3e\u548c\u689d\u4ef6",
  "reportError": "\u5831\u544a\u932f\u8aa4",
  "receiveMessageVia": "\u63a5\u6536\u6d88\u606f \u901a\u904e",
  "yourPhoneNumber": "\u4f60\u7684\u865f\u78bc",
  "weNeedToMakeSureThat": "\u6211\u5011\u9700\u8981\u78ba\u4fdd",
  "isYourPhoneNumber": "\u662f\u60a8\u7684\u865f\u78bc\u3002",
  "needToProcess": "\u6211\u5011\u5c07\u6839\u64da\u500b\u4eba\u6578\u64da\u6cd5\u8655\u7406\u60a8\u7684\u6578\u64da\u3002\u672a\u7d93\u60a8\u7684\u540c\u610f\uff0c\u5b83\u7d55\u4e0d\u6703\u88ab\u5206\u4eab\u3002",
  "gotIt": "\u540c\u610f",
  "enterAnotherPhoneNumber": "\u8f38\u5165\u53e6\u4e00\u500b\u96fb\u8a71\u865f\u78bc",
  "thePhoneNumber": "\u96fb\u8a71\u865f\u78bc ",
  "isNotRegisterWhatsApp": " \u672a\u5728 WhatsApp \u8a3b\u518a",
  "cancel": "\u53d6\u6d88",
  "reachMaximumResendTime": "\u60a8\u5df2\u9054\u5230\u91cd\u65b0\u767c\u9001\u6d88\u606f\u7684\u6700\u5927\u6b21\u6578\u3002 \u8acb\u518d\u6b21\u8f38\u5165\u60a8\u7684\u96fb\u8a71\u865f\u78bc\u3002",
  "weJustSendTo1": "\u6211\u5011\u525b\u525b\u5411\u60a8\u7684",
  "weJustSendTo2": "\u767c\u9001\u4e86\u4e00\u689d\u6d88\u606f\u3002",
  "resend": "\u91cd\u767c",
  "reenterPhoneNumber": "\u91cd\u65b0\u8f38\u5165\u96fb\u8a71\u865f\u78bc",
  "dontReceive": "\u6c92\u6709\u6536\u5230\u4efb\u4f55\u6d88\u606f\uff1f",
  "reward": "\u5831\u916c",
  "getYourGiftNow": "\u9818\u53d6\u4f60\u7684\u79ae\u7269",
  "earn": "\u8cfa",
  "use": "\u4f7f\u7528",
  "func": "\u529f\u80fd",
  "engage": "\u5f9e\u4e8b",
  "expiredDate": "\u5230\u671f\u65e5:",
  "viewMore": "\u67e5\u770b\u66f4\u591a",
  "items": "\u9805",
  "useNow": "\u4f7f\u7528",
  "earnMore": "\u8cfa\u66f4\u591a",
  "useLater": "\u7a0d\u5f8c\u4f7f\u7528",
  "yourRedeemCode": "\u4f60\u7684\u514c\u63db\u78bc",
  "giveThisCodeToStore": "\u8acb\u5c07\u6b64\u4ee3\u78bc\u63d0\u4f9b\u7d66\u5546\u5e97\u54e1\u5de5",
  "viewOther": "\u67e5\u770b\u5176\u4ed6",
  "selectCountry": "\u9078\u64c7\u570b\u5bb6",
  "offerAlreadyInUse": "\u6b64\u512a\u60e0\u5df2\u88ab\u4f7f\u7528\uff0c\u4f46\u9084\u6709\u5176\u4ed6\u512a\u60e0\u5238\u7b49\u8457\u60a8\u3002",
  "english": "English",
  "cantonese": "\u5ee3\u6771\u8a71",
  "useVoucherExpired": "\u6b64\u6d3b\u52d5\u512a\u60e0\u5238\u5df2\u7528\u5b8c\uff0c\u4f46\u9084\u6709\u5176\u4ed6\u512a\u60e0\u5238\u7b49\u8457\u60a8\u3002",
  "engageAndEarn": "\u53c3\u8207\u4e26\u7372\u5f97\u514d\u8cbb\u734e\u52f5",
  "pleaseEarnMore": "\u8acb\u8a2a\u554f\u8cfa\u53d6\u9801\u9762\u4ee5\u53c3\u8207\u4e26\u8cfa\u53d6\u734e\u52f5\u3002",
  "sortByDate": "\u6309\u65e5\u671f\u6392\u5e8f",
  "sortByAlphabet": "\u6309\u5b57\u6bcd\u6392\u5e8f",
  "openCamera": "\u6253\u958b\u76f8\u6a5f\u6383\u63cf\u4e8c\u7dad\u78bc",
  "enterOUCode": "\u8f38\u5165\u55ae\u4f4d\u4ee3\u78bc",
  "enterCouponCode": "\u8f38\u5165\u60a8\u7684\u512a\u60e0\u5238\u4ee3\u78bc",
  "scanQRToRegister": "\u6383\u63cf\u4e8c\u7dad\u78bc\u5831\u540d",
  "scanQRToRedeem": "\u6383\u63cf\u4e8c\u7dad\u78bc\u514c\u63db",
  "scanQRToDownload": "(ZH) Scan QR Code to Download App",
  "howToRegister": "\u8acb\u8f38\u5165\u5728\u667a\u80fd\u7ba1\u5bb6\u5e73\u53f0\u5275\u5efa\u7684\u004f\u0055\u78bc\uff0c\u7136\u5f8c\u6383\u63cf\u552e\u8ca8\u6a5f\u4e0a\u7684\u4e8c\u7dad\u78bc\u3002",
  "howToRedeem": "\u8acb\u8f38\u5165\u5728\u667a\u7ba1\u5bb6\u5e73\u53f0\u5275\u5efa\u7684\u512a\u60e0\u5238\u4ee3\u78bc\uff0c\u7136\u5f8c\u6383\u63cf\u552e\u8ca8\u6a5f\u4e0a\u7684\u4e8c\u7dad\u78bc\u3002",
  "pasteURL": "\u7c98\u8cbc\u60a8\u7684\u0020\u0057\u0068\u0061\u0074\u0073\u0041\u0070\u0070\u0020\u9a57\u8b49\u93c8\u63a5",
  "pasteURLError": "\u8acb\u5f9e\u0020\u0077\u0068\u0061\u0074\u0073\u0061\u0070\u0070\u0020\u6d88\u606f\u4e2d\u5fa9\u5236\u60a8\u7684\u0020\u0057\u0068\u0061\u0074\u0073\u0041\u0070\u0070\u0020\u9a57\u8b49\u93c8\u63a5",
  "downloadAppInstructionStart": "Please scan the QR code on the vending machine to start the coupon redemption",
  "downloadAppInstruction1": "1. Please download the app from App Store and come back this page",
  "downloadAppInstruction2": "2. Please open the app via below button",

}

// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
const resources = {
  [LIST_LANGUAGE_ENUM.EN]: { translation: translationEN },
  [LIST_LANGUAGE_ENUM.ZH_HK]: { translation: translationZH_HK },
};

i18n
  // eslint-disable-next-line
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: I18N_FALLBACK_LNG,
    debug: false,
    whitelist: I18N_WHITELIST,
    resources,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
