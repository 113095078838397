import SVG from "react-inlinesvg";
import { useNavigate } from "react-router-dom";
import WhatsAppIcons from "../../assets/icons/whatsapp.svg";
import { Button, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

const PasteURLButtonWrapper = styled(Button)({
  "& .link": {
    color: "#1155AA"
  }
});

const PasteURLButton = (props: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  return (
    <PasteURLButtonWrapper
      color="error"
      size="large"
      startIcon={<SVG height={"40px"} width={"40px"} src={WhatsAppIcons} />}
      onClick={() => {
        navigator.clipboard.readText()
          .then(text => {
            try {
              const url = new URL(text.replace(/\s/g, ''));
              navigate(url.pathname)
            } catch (err) {
              alert(t("pasteURLError"))
            }
          })
          .catch(err => {
            alert(t("pasteURLError"))
          });
      }}
      {...props}
    >
      <a className="link" >
        {t("pasteURL")}
      </a>
    </PasteURLButtonWrapper>
  );
};

export default PasteURLButton;
