import React, { useState, useEffect } from "react";
import { QrReader } from "react-qr-reader";
import { Box, Button, styled, TextField, Backdrop, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import AuthenticateLayout from "../../components/AuthenticateLayout";
import RewardButton from "../../components/RewardButton";
import SVG from "react-inlinesvg";
import RewardWhiteIcon from "../../assets/icons/Reward-white.svg";
import CircularProgress from '@mui/material/CircularProgress';
import Gift3 from "../../assets/icons/gift3.svg";
import Gift4 from "../../assets/icons/gift4.svg";
import { TextBox } from "../authenticate/resend-phone-number";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { getFullAPIURL } from "configs/http-client";
import CustomizedDialogs from "components/Dialog/CustomizedDialogs"
import { convertObjectToView } from "utils/content";
import BackgroundImg from "../../assets/images/banner3.png";
import banner from "assets/images/banner4.png"

const EngageWrapper = styled(Box)({
  height: "-webkit-fill-available",
  "& .MuiStepConnector-line": {
    borderColor: "#EF1B1B",
    borderLeftStyle: "dashed",
  },
  "& .MuiStepConnector-root": {
    marginLeft: 8,
  },
  "& .MuiStepLabel-label": {
    fontSize: 16,
    lineHeight: "21px",
  },
  "& .MuiStepLabel-iconContainer": {
    paddingRight: 16,
  },
  "& .MuiStepper-root": {
    paddingBottom: 15,
    borderBottom: "1px solid #D9D9D9",
  },
  "& .MuiStepLabel-root": {
    padding: 0,
    overflow: "hidden",
  },
});

const Footer = styled(Box)(`
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 10px 15px 40px 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background: #fff;
`);

const GetGiftBtn = styled(Button)({
  width: "100%",
  fontWeight: 400,
  fontSize: 14,
  height: 48,
  position: "relative",
  "&:hover": {
    backgroundColor: "#1155AA",
  },
  "& .giftIcon1": {
    position: "absolute",
    bottom: 0,
    left: "14%",
  },
  "& .giftIcon2": {
    position: "absolute",
    top: 0,
    right: "20%",
  },
});

const ScanRegisterQR = (props) => {
  const [openCamera, setOpenCamera] = useState(false);
  const [ouCode, setouCode] = useState(null);
  const [nsmTokken, setNsmTokken] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogInfo, setDialogInfo] = useState({ title: '', body: '', footer: '' });
  const { t } = useTranslation();
  const handleDialogClose = () => {
    setOpenDialog(false)
  }
  const sendRegister = (session_id) => {
    fetch(getFullAPIURL("/api/post/register-front"), {
      method: "POST",
      headers: {
        Authorization:
          `Bearer ${nsmTokken}`,
          "Content-Type": "application/json",
      },
      body: JSON.stringify({
        session_id: session_id,
        ou_code: ouCode,
      }), // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        setOpenDialog(true)
        setDialogInfo({ title: <img src={BackgroundImg} width={"100%"} />, body: convertObjectToView(data), footer: '' })
      })
      .catch((error) => {
        setOpenDialog(true)
        setDialogInfo({ title: <img src={BackgroundImg} width={"100%"} />, body: convertObjectToView(error), footer: '' })
      });
  };

  useEffect(() => {
    fetch(getFullAPIURL("/api/token-auth/"), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: "admin",
        password: "a3PFXD3LKdpCiF"
      }), // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success get token:", data);
        setNsmTokken(data.token);
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("Error");
      });
  }, []);


  return (
    <EngageWrapper>
      <CustomizedDialogs open={openDialog} onClose={handleDialogClose} {...dialogInfo} />
      <AuthenticateLayout button={RewardButton} background={banner}>
        <Box m={2} pt={3}>
          <TextField
            id="filled-basic"
            label={t("enterOUCode")}
            variant="filled"
            fullWidth
            onChange={(e) => {
              setOpenCamera(false);
              setouCode(e.target.value);
            }}
          />
          <TextBox style={{ backgroundColor: "rgba(17, 85, 170, 0.1)" }}>
            <Box mr={2}>
              <InfoRoundedIcon color="info" style={{ color: "rgb(111 147 239)" }} />
            </Box>
            <Typography variant="subtitle1">
              {/* eslint-disable-next-line max-len */}
              <p>{t("howToRegister")}</p>
            </Typography>
          </TextBox>
        </Box>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openCamera}
          onClick={() => {
            setOpenCamera(!openCamera);
          }}
        >
          <div style={{ position: "absolute", height: "100%", top: "48%" }}>
            <CircularProgress color="inherit" />
          </div>
          <Box m={3} pt={1} style={{ width: "100%" }}>
            {openCamera && (
              <QrReader
                constraints={{ facingMode: "environment" }}
                onResult={(result) => {
                  if (result) {
                    setOpenCamera(false);

                    const url = new URL(result?.text.toString());
                    const params = new URLSearchParams(url.search);
                    const session_id = params.get("session_id");
                    sendRegister(session_id);
                  }
                }}
                style={{ width: "100%" }}
              />
            )}
          </Box>
        </Backdrop>
        <Footer>
          <GetGiftBtn
            variant="contained"
            startIcon={<SVG src={RewardWhiteIcon} />}
            onClick={() => {
              setOpenCamera(!openCamera);
            }}
          >
            <SVG className="giftIcon1" src={Gift3} />
            <SVG className="giftIcon2" src={Gift4} />
            {t("openCamera")}
          </GetGiftBtn>
        </Footer>
      </AuthenticateLayout>
    </EngageWrapper>
  );
};

export default ScanRegisterQR;
