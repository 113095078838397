export const APP_NAME = {
  WHATSAPP: "WhatsApp",
  LINE: "Line",
  SMS: "SMS"
};

export const LIST_LANGUAGE_ENUM = {
  EN: "en",
  ZH_HK: "zh_TW"
};

export const SUB_CAMPAIGN_ID_KEY = "subcampaign_id";
