import SVG from "react-inlinesvg";
import ReportIcons from "../../assets/icons/report.svg";
import { Button, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

const ReportButtonWrapper = styled(Button)({
  "& .link": {
    color: "#EF1B1B"
  }
});

const ReportButton = (props: any) => {
  const { t } = useTranslation();
  return (
    <ReportButtonWrapper
      color="error"
      size="large"
      startIcon={<SVG src={ReportIcons} />}
      {...props}
    >
      <a className="link" href={"mailto:cs@smartretail.co"}>
        {t("reportError")}
      </a>
    </ReportButtonWrapper>
  );
};

export default ReportButton;
