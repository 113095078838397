import httpClient from "../../configs/http-client";
import apiUrl from "../../constants/apiUrl";
// import { IPhoneRegistration } from "./auth.interface";

export const postRegistration = async ({
                                         prefix,
                                         phoneNumber,
                                         subCampaignId,
                                         platform,
                                         resend = false,
                                         ouCode = ""
}: any) => {
  return httpClient.post(
    apiUrl.REGISTRATION,
    {
      prefix: prefix,
      phone_number: phoneNumber,
      subcampaign_id: subCampaignId,
      platform: platform,
      resend: resend,
      ou_code: ouCode
    }
  ).then((res) => res.data);
};

export const getVerifyRegistration = async ({ data }: { data: string }) => {
  return httpClient.get(
    apiUrl.VERIFY_REGISTRATION + data,
  ).then((res) => res.data);
};

