import { Box, Button, styled, Typography, Divider } from "@mui/material";
import Logo from "../../../assets/logos/logo.png";
import { LogoBanner, SubmitBtn } from "../login/ConfirmPhoneNumber";
import CheckIcon from "../../../assets/icons/Check.svg";
import SVG from "react-inlinesvg";
import Countdown from "react-countdown";
import React, { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { postRegistration } from "../../../services/auth/auth.service";
import ChangeLanguage from "../../../components/ChangeLanguage";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { Loading } from "../../../components/Loading";
import { links } from "../../../constants/links";
import { getValueByLanguage, renderPhoneNumber } from "../../../utils/utils";
import { useTranslation } from "react-i18next";
import { SUB_CAMPAIGN_ID_KEY } from "../../../constants";
import RewardIcon from "../../../assets/icons/Reward.svg";
import ProductIcon from "../../../assets/icons/StarBucks.png";
import Watermark from "../../../assets/images/wattermark.png";
import PasteURLButton from "components/PasteURLButton"

const ResendWrapper = styled(Box)({
  padding: "0 20px",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  paddingBottom: 150,
  "&.error": {
    paddingBottom: 0
  }
});

const ResendBody = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "space-between"
});

export const TextBox = styled(Box)({
  marginTop: 30,
  backgroundColor: "#E7F9EB",
  borderRadius: 10,
  padding: "12px 15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& p": {
    color: "#000000",
    opacity: 0.7
  },
  "& .termBtn": {
    fontSize: 14,
    padding: 0,
    marginTop: 30
  }
});

const ErrorBox = styled(Box)({
  marginTop: 30,
  backgroundColor: "#FDEFE8",
  borderRadius: 10,
  padding: "12px 15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& p": {
    color: "#000000",
    opacity: 0.7
  },
  "& .termBtn": {
    fontSize: 14,
    padding: 0,
    marginTop: 30
  }
});

const IconWrapper = styled(Box)({
  width: 70,
  height: 70,
  background: "linear-gradient(180deg, #FFFFFF 0%, #D0D5DD 100%), rgba(255, 255, 255, 0.2)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "12px",
  "& img": {
    width: 42,
    height: 42
  }
});

const ResendBtn = styled(Button)({
  fontSize: 14,
  fontWeight: 400,
  padding: 0,
  justifyContent: "flex-start",
});

const CampaignContent = styled(Box)({
  width: 258,
  height: 258,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundImage: `url(${Watermark})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  marginTop: 50,
  flexDirection: "column",
  "& p": {
    opacity: 0.7
  }
});

const CountLabel = styled("span")({
  color: "#8D8D8D"
});

const ResendPhoneNumber = () => {
  const [isCounting, setIsCounting] = useState(false);
  const { state } = useLocation();
  const { data } = state;
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mutate, isLoading } = useMutation(postRegistration);
  const handleResend = () => {
    setIsCounting(true);
    mutate({
      prefix: state?.prefix,
      phoneNumber: state?.phoneNumber,
      platform: "WHATSAPP",
      subCampaignId: localStorage.getItem(SUB_CAMPAIGN_ID_KEY),
      resend: true
    },
      {
        onSuccess: (res) => {
          if (res.error_msg) {
            setIsCounting(false);
            setError("reachMaximumResendTime");
          }
        }
      });
  };

  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      // Render a completed state
      setIsCounting(false);
      return <ResendBtn onClick={handleResend} color="info">{t("resend")}</ResendBtn>;
    } else {
      // Render a countdown
      return <CountLabel>
        {`${t("resend")} (${minutes}:${seconds.toString().length < 2 ? ("0" + seconds) : seconds})`}
      </CountLabel>;
    }
  };

  const renderCountDown = useMemo(() => {
    return (
      <Countdown zeroPadTime={1} date={Date.now() + 60000} renderer={renderer} onComplete={() => setIsCounting(false)}>
        <></>
      </Countdown>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCounting, setIsCounting]);


  return (
    <ResendWrapper className={error ? "error" : ""}>
      {isLoading && <Loading />}
      <LogoBanner>
        <img className="logo" src={Logo} alt="logo" />
        <ChangeLanguage />
      </LogoBanner>
      <ResendBody>
        <Box>
          {
            error ? (
              <ErrorBox>
                <Box mr={2}>
                  <ErrorRoundedIcon color="error" />
                </Box>
                <Typography variant="subtitle1">
                  {t(error)}
                </Typography>
              </ErrorBox>
            ) : (
              <TextBox>
                <Box mr={2}>
                  <SVG src={CheckIcon} />
                </Box>
                <Typography variant="subtitle1">
                  {/* eslint-disable-next-line max-len */}
                  {t("weJustSendTo1") + " "} <b>{state?.appName}</b> <b>{renderPhoneNumber(state?.prefix, state?.phoneNumber)}</b> {t("weJustSendTo2")}
                </Typography>
              </TextBox>
            )
          }
          {
            !error && (
              <Box mt={1} display="flex" justifyContent="center" alignItems="baseline">

                <Typography mr={0.5} textAlign="center">{t("dontReceive")}</Typography>
                {
                  isCounting ? renderCountDown
                    : <ResendBtn onClick={handleResend} color="info">{t("resend")}</ResendBtn>
                }
              </Box>
            )
          }
        </Box>
        <CampaignContent>
          <IconWrapper>
            {
              data ? <img src={data?.advertiser_logo ?? ProductIcon} alt="icon" /> : <SVG src={RewardIcon} />
            }
          </IconWrapper>
          <Typography
            fontWeight={700}
            mt={2}
            color="primary"
            variant="body2"
            textAlign="center"
          >
            {
              data ? getValueByLanguage(data, "reward_summary") : t("engageAndEarn")
            }
          </Typography>
          <Divider style={{margin: "5%"}} />
          <PasteURLButton />
        </CampaignContent>
      </ResendBody>
      {
        error && (
          <SubmitBtn
            onClick={() => navigate(links.LOGIN)}
            className="submitBtn"
            variant="contained"
          >
            {t("reenterPhoneNumber")}
          </SubmitBtn>
        )
      }
    </ResendWrapper>
  );
};

export default ResendPhoneNumber;
