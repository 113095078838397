import httpClient from "../../configs/http-client";
import apiUrl from "../../constants/apiUrl";
// import { IAds } from "./ads.interface";

export const getAds = async ({ type = "earn", offset = 0, limit = 10, orderBy }: any) => {
  return httpClient.get(
    apiUrl.LIST_SUB_CAMPAIGN,
    {
      params: {
        type: type,
        offset,
        limit,
        order_by: orderBy
      }
    }
  ).then((res) => res.data);
};

export const getAdsDetail = async ({ id }: { id: string }) => {
  return httpClient.get(
    apiUrl.SUB_CAMPAIGN_DETAIL + id
  ).then((res) => res.data);
};


export const getSubCampaignRegister = async ({ id }: { id: string | null }) => {
  return httpClient.get(
    apiUrl.SUB_CAMPAIGN_REGISTER + id
  ).then((res) => res.data);
};

