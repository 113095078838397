import React, { useContext, useState } from "react";
// import { IAppConfig } from "../services/app-config-example/app-config.interface";
// import { MayBe } from "../types";
import { Props } from "../utils/utils";

export const AppConfigContext = React.createContext<any>(null);

export const SetAppConfigContext = React.createContext<React.Dispatch<React.SetStateAction<any>>>(
  () => null
);

export const useAppConfig = (): any => useContext(AppConfigContext);

export const useSetAppConfig = (): React.Dispatch<React.SetStateAction<any>> =>
  useContext(SetAppConfigContext);

export const AppProvider: React.FC<Props> = ({ children }) => {
  const [user, setApp] = useState<any>(null);
  return (
    <AppConfigContext.Provider value={user}>
      <SetAppConfigContext.Provider value={setApp}>{children}</SetAppConfigContext.Provider>
    </AppConfigContext.Provider>
  );
};
